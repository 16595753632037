import React from 'react'
import AboutImg from '../../assets/images/abtbanshape.png'
import Icon01 from '../../assets/images/title_icon.png'
import abt01 from '../../assets/images/abtban_01.png'
import abt02 from '../../assets/images/abtban_02.png'
import abt03 from '../../assets/images/abtban_03.png'
import abt04 from '../../assets/images/abtban_04.png'
import abt05 from '../../assets/images/abtban_05.png'

function AbtBanner() {
  return (
    <>
      <section className="abt_banner">
        <div className="right_shape position-absolute">
          <img src={AboutImg} alt="img" />
        </div>
        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
            Our Story{" "}
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
            HIGHWAY STREET <br /> CAFE & RESTAURANT
          </h2>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Discover the Essence of Lucknawi Culinary Art:
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Indulge your senses in a symphony of aromatic spices and intricate blends that define the essence of Lucknawi cuisine. From the iconic kebabs to the royal biryanis, each dish is a masterpiece crafted with passion and authenticity.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Signature Dishes:
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          1.	Galouti Kebabs: Melt-in-your-mouth kebabs that whisper tales of the royal kitchens.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          2.	Lucknawi Biryani: A fragrant dance of basmati rice, succulent meat, and exotic spices.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          3.	Tunday Kababi Special: Experience the legendary kebabs that have stood the test of time.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Ambiance:
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Step into our elegant yet warm ambiance, where the charm of Lucknawi hospitality awaits. Traditional artistry and contemporary design merge seamlessly to create an inviting space for families, friends, and food enthusiasts.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Hospitality Redefined:
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Our courteous staff is committed to ensuring a memorable dining experience. Immerse yourself in the hospitality that reflects the cultural ethos of Lucknow.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Events and Catering:
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Celebrate your special moments with our catering services. Whether it’s an intimate gathering or a grand celebration, let the flavors of Lucknawi cuisine elevate your event.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Visit Us:
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Embark on a culinary voyage at Highway Street, where every dish tells a story, and every meal is an ode to the culinary heritage of Lucknow. Join us and savor the true taste of Lucknawi tradition.
          </p>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Indulge. Experience. Celebrate.
          </p>
        </div>
        <div className="abt_img">
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt01} alt="img" />
          </div>
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt02} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={abt03} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt04} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt05} alt="img" />
          </div>
        </div>
      </section>
    </>
  )
}

export default AbtBanner