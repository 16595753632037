import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import BG1 from '../../assets/images/slide1.jpg'
import BG2 from '../../assets/images/slide2.jpg'
import BG3 from '../../assets/images/slide3.jpg'

const Bannerslider = {
    // responsive: [
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 3,
    //         },
    //     },
    //     {
    //         breakpoint: 1000,
    //         settings: {
    //             slidesToShow: 4,
    //         },
    //     },
    // ],
}

function Banner() {
    return (
        <>
            <section className="banner_section second_banner">
                {/* <div className="social_media_side side_fixer">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/" target='_blank'>Facebook</a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target='_blank'>Instagram</a>
                        </li>
                        <li>
                            <a href="https://twitter.com/" target='_blank'>Twitter</a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/" target='_blank'>Youtube</a>
                        </li>
                    </ul>
                </div>
                <div className="timing_side side_fixer">
                    <ul>
                        <li>
                            <span>Mon - Fri : 8AM - 9PM </span>
                        </li>
                        <li>
                            <span>Sat - Sun : 8AM - 11PM</span>
                        </li>
                    </ul>
                </div> */}
                <div className="banner_slider" data-aos="fade-up" data-aos-duration={3000} data-aos-offset={0} >
                    <Slider className="" id="banner_slider" {...Bannerslider} arrows={false} slidesToShow={1} autoplay={true} autoplaySpeed={5000}>
                        <div className="item">
                            <div className="slider_block" style={{ backgroundImage: `url(${BG1})` }} >
                                <div className="ban_text ">
                                    <div className="inner_text">
                                        <span className="sub_text">Welcome to</span>
                                        <h1>Highway Street</h1>
                                        <p className='lead'>Cafe & Restaurant</p>
                                        {/* <Link href="#" className="btn btn_primary">
                                            Coming Soon
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="slider_block" style={{ backgroundImage: `url(${BG2})` }} >
                                <div className="ban_text ">
                                    <div className="inner_text">
                                        <span className="sub_text">Highway Street</span>
                                        <h1>We Serve Desi Emotions with Delicious Food</h1>
                                        {/* <p className='lead'>Explore the Richness of Middle Eastern Cuisine. Savor the exotic flavors and spices of our authentic dishes.</p> */}
                                        {/* <Link href="" className="btn btn_primary">
                                            Coming Soon
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="slider_block" style={{ backgroundImage: `url(${BG3})` }} >
                                <div className="ban_text">
                                    <div className="inner_text">
                                        <span className="sub_text">Delicious</span>
                                        <h1>Awadhi - Mughlai - Indo Chinese - Continental</h1>
                                        <p className='lead'>Lehje me nazaqat. Baton me tameez. Khane ki khusbbu. Jo dilo ko karde kareeb. Yahi to hai lakhnawi tehzeeb.</p>
                                        {/* <Link href="" className="btn btn_primary">
                                            Coming Soon
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Banner