import React,{useEffect} from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import MenuList3Bred from '../BredCrumb/MenuList3Bred'
import MenuSection3 from '../MenMenuList/MenuSection3'
import Reservation from '../MenMenuList/Reservation'
import Aos from 'aos'
import menu1 from '../../assets/images/menu-1.jpeg'
import menu2 from '../../assets/images/menu-2.jpeg'
import { Link } from 'react-router-dom'
import MenuBanner from './MenuBanner'



function Main() {

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (

    <div className='page_wrapper'>
      <Header />
      {/* <MenuList3Bred /> */}
      <MenuBanner />
      {/* <MenuSection3/> */}
      {/* <Reservation/> */}
      {/* <section className="our_menu_section row_inner_am light_texchur"> */}
      <section className="lead" data-aos="fade-up" data-aos-duration={1500} style={{ marginTop: 10}}>
        {/* <div className="container"> */}
          <img src={menu1} width={'100%'}/>
          <img src={menu2} width={'100%'}/>
        {/* </div> */}

        {/* <div className="text-center" style={{paddingTop: 30}}>
          <Link to="/assets/files/A3 MENU.pdf" className="btn btn_primary">
            download pdf menu
          </Link>
        </div> */}
      </section>
      <Footer />
    </div>
  )
}

export default Main