import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react';

import galleryImageOne from '../../assets/images/galleryNewImages/galleryImage(1).jpeg'
import galleryImageTwo from '../../assets/images/galleryNewImages/galleryImage(2).jpeg'
import galleryImageThree from '../../assets/images/galleryNewImages/galleryImage(3).jpeg'
import galleryImageFour from '../../assets/images/galleryNewImages/galleryImage(4).jpeg'
import galleryImageFive from '../../assets/images/galleryNewImages/galleryImage(5).jpeg'
import galleryImageSix from '../../assets/images/galleryNewImages/galleryImage(20).jpeg'
import galleryImageSeven from '../../assets/images/galleryNewImages/galleryImage(7).jpeg'
import galleryImageEight from '../../assets/images/galleryNewImages/galleryImage(8).jpeg'
import galleryImageNine from '../../assets/images/galleryNewImages/galleryImage(9).jpeg'
import galleryImageTen from '../../assets/images/galleryNewImages/galleryImage(22).jpeg'
import galleryImageEleven from '../../assets/images/galleryNewImages/galleryImage(11).jpeg'
import galleryImageTwelve from '../../assets/images/galleryNewImages/galleryImage(23).jpeg'
import galleryImageThirteen from '../../assets/images/galleryNewImages/galleryImage(13).jpeg'
import galleryImageFourteen from '../../assets/images/galleryNewImages/galleryImage(14).jpeg'
import galleryImageFifteen from '../../assets/images/galleryNewImages/galleryImage(15).jpeg'
import galleryImageSixteen from '../../assets/images/galleryNewImages/galleryImage(16).jpeg'
import galleryImageSeventeen from '../../assets/images/galleryNewImages/galleryImage(17).jpeg'
import galleryImageEighteen from '../../assets/images/galleryNewImages/galleryImage(18).jpeg'
import galleryImageNineteen from '../../assets/images/galleryNewImages/galleryImage(19).jpeg'
import galleryImageTwentyOne from '../../assets/images/galleryNewImages/galleryImage(21).jpeg'
import galleryImageTwentyTwo from '../../assets/images/galleryNewImages/galleryImage(24).jpeg'
import galleryImageTwentyThree from '../../assets/images/galleryNewImages/galleryImage(25).jpeg'
import galleryImageTwentyFour from '../../assets/images/galleryNewImages/galleryImage(26).jpeg'
import galleryImageTwentyFive from '../../assets/images/galleryNewImages/galleryImage(27).jpeg'
import galleryImageTwentySix from '../../assets/images/galleryNewImages/galleryImage(28).jpeg'
import galleryImageTwentySeven from '../../assets/images/galleryNewImages/galleryImage(29).jpeg'
import galleryImageTwentyEight from '../../assets/images/galleryNewImages/galleryImage(30).jpeg'
import galleryImageTwentyNine from '../../assets/images/galleryNewImages/galleryImage(31).jpeg'
import galleryImageThirty from '../../assets/images/galleryNewImages/galleryImage(32).jpeg'
import galleryImageThirtyOne from '../../assets/images/galleryNewImages/galleryImage(33).jpeg'
import galleryImageThirtyTwo from '../../assets/images/galleryNewImages/galleryImage(34).jpeg'
import galleryImageThirtyThree from '../../assets/images/galleryNewImages/galleryImage(35).jpeg'
import galleryImageThirtyFour from '../../assets/images/galleryNewImages/galleryImage(36).jpeg'
import galleryImageThirtyFive from '../../assets/images/galleryNewImages/galleryImage(37).jpeg'
import galleryImageThirtySix from '../../assets/images/galleryNewImages/galleryImage(38).jpeg'
import galleryImageThirtySeven from '../../assets/images/galleryNewImages/galleryImage(39).jpeg'
import galleryImageThirtyEight from '../../assets/images/galleryNewImages/galleryImage(40).jpeg'
import galleryImageThirtyNine from '../../assets/images/galleryNewImages/galleryImage(41).jpeg'
import galleryImageFourty from '../../assets/images/galleryNewImages/galleryImage(42).jpeg'
import galleryImageFourtyOne from '../../assets/images/galleryNewImages/galleryImage(43).jpeg'
import galleryImageFourtyTwo from '../../assets/images/galleryNewImages/galleryImage(44).jpeg'
import galleryImageFourtyThree from '../../assets/images/galleryNewImages/galleryImage(45).jpeg'

function Gallery() {

  const [newImages, setNewImages] = useState([
    galleryImageOne,
    galleryImageTwo,
    galleryImageThree,
    galleryImageFour,
    galleryImageFive,
    galleryImageSix,
    galleryImageSeven,
    galleryImageEight,
    galleryImageNine,
    galleryImageTen,
    // galleryImageEleven,
    galleryImageTwelve,
    // galleryImageThirteen,
    galleryImageFourteen,
    galleryImageFifteen,
    galleryImageSixteen,
    galleryImageSeventeen,
    galleryImageEighteen,
    galleryImageNineteen,
    galleryImageTwentyOne,
    galleryImageTwentyTwo,
    galleryImageTwentyThree,
    galleryImageTwentyFour,

    galleryImageFourtyThree,

    galleryImageTwentyFive,
    galleryImageTwentySix,
    galleryImageTwentySeven,
    // galleryImageTwentyEight,
    galleryImageTwentyNine,
    galleryImageThirty,
    // galleryImageThirtyOne,
    galleryImageThirtyTwo,
    galleryImageThirtyThree,
    galleryImageThirtyFour,
    // galleryImageThirtyFive,
    galleryImageThirtySix,
    galleryImageThirtySeven,
    galleryImageThirtyEight,
    galleryImageThirtyNine,
    galleryImageFourty,
    galleryImageFourtyOne,
    galleryImageFourtyTwo,
    // galleryImageFourtyThree
  ])

  const [img, setImg] = useState()
  const [img1, setImg1] = useState()
  const [img2, setImg2] = useState()
  const [img3, setImg3] = useState()
  const [img4, setImg4] = useState()
  const [img5, setImg5] = useState()
  const [img6, setImg6] = useState()
  const [img7, setImg7] = useState()
  const [img8, setImg8] = useState()
  const [img9, setImg9] = useState()
  const [img10, setImg10] = useState()
  const [img11, setImg11] = useState()

  const [toggler, setToggler] = useState(false);

  const [activeImage, setActiveImage] = useState(1)

  return (
    <>
      <section className="row_am gallery_section">
        <div className="container">

          <div className="row">

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageOne} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(1) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwo} alt="img" />
                <Link to="#" onClick={() => setImg1(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(2) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThree} alt="img" />
                <Link to="#" onClick={() => setImg2(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(3) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFour} alt="img" />
                <Link to="#" onClick={() => setImg3(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(4) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFive} alt="img" />
                <Link to="#" onClick={() => setImg4(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(5) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageSix} alt="img" />
                <Link to="#" onClick={() => setImg5(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(6) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageSeven} alt="img" />
                <Link to="#" onClick={() => setImg6(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(7) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageEight} alt="img" />
                <Link to="#" onClick={() => setImg7(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(8) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageNine} alt="img" />
                <Link to="#" onClick={() => setImg8(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(9) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTen} alt="img" />
                <Link to="#" onClick={() => setImg9(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(10) }}>
                </Link>
              </div>
            </div>


            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwelve} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(11) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFourteen} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(12) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFifteen} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(13) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageSixteen} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(14) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageSeventeen} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(15) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageEighteen} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(16) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageNineteen} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(17) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentyOne} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(18) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentyTwo} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(19) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentyThree} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(20) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentyFour} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(21) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFourtyThree} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(22) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentyFive} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(23) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentySix} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(24) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentySeven} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(25) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageTwentyNine} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(26) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirty} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(27) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtyTwo} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(28) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtyThree} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(29) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtyFour} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(30) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtySix} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(31) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtySeven} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(32) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtyEight} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(33) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageThirtyNine} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(34) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFourty} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(35) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFourtyOne} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(36) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryImageFourtyTwo} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(42) }}>
                </Link>
              </div>
            </div>
            
          </div>

        </div>
      </section>

      <FsLightbox toggler={toggler} sources={newImages} slide={activeImage} />
    </>
  )
}

export default Gallery