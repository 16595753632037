import React from 'react'
import AboutImg from '../../assets/images/abtbanshape.png'
import Icon01 from '../../assets/images/title_icon.png'

function MenuBanner() {
  return (
    <>
      <section className="abt_banner">
        <div className="right_shape position-absolute">
          <img src={AboutImg} alt="img" />
        </div>
        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
            Our Menu
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
            delicious tasty dishes in our menu
          </h2>
        </div>
        
      </section>
    </>
  )
}

export default MenuBanner